
  import {defineComponent, computed, PropType, Ref, reactive, toRefs, watch, onMounted} from "vue";
  import EmployeeAutocomplete from "@/components/EmployeeAutocomplete.vue";
  import { OrderTask } from "@/model/OrderTaskImport";
  import Employee from "@/model/Employee";
  import EmployeeService from "@/service/EmployeeService";

  export default defineComponent({
    name: "InventoriesTasksForm",
    components: {
      EmployeeAutocomplete
    },
    props: {
      task: {
        required: true,
        type: Object as PropType<OrderTask>
      },
      orders: {
        required: true,
        type: Array as PropType<Array<{ label: string; orderId: number }>>
      },
      errors: {
        required: false,
        type: Map as PropType<Map<string, string | Ref<string>>>,
        default: () => new Map<string, string>()
      }
    },
    setup(props, context) {

      const state = reactive({
        employee: null as Employee | null | undefined,
        orderId: null as number | null
      });

      const taskInputModel = computed({
        get(): OrderTask {
          return props.task;
        },
        set(newValue: OrderTask) {
          context.emit("taskChanged", newValue);
        },
      });

      const onSelectOrder = (orderId: number) => {
        taskInputModel.value = {
          ...taskInputModel.value,
          orderId
        };
      };

      const onSelectEmployee = (employee: Employee) => {
        state.employee = employee;
      };

      const loadEmployeeFromTask = (task: OrderTask) => {
        if (task?.employeeId) {
          EmployeeService.getEmployeeById(task.employeeId)
            .then((response) => {
              if (response?.data) state.employee = response.data;
            });
        } else {
          state.employee = null;
        }
      };

      onMounted(() => {
        loadEmployeeFromTask(props.task);
      });

      watch(() => props.task, () => {
        loadEmployeeFromTask(props.task);
      });

      watch(() => state.employee, () => {
        const employeeId = state.employee?.employeeId;
        if (taskInputModel.value.employeeId != employeeId) {
          taskInputModel.value = {
            ...taskInputModel.value,
            employeeId
          };
        }
      });

      return {
        ...toRefs(state),
        taskInputModel,
        onSelectOrder,
        onSelectEmployee
      };
    }
  });
