
  import {computed, defineComponent, onMounted, reactive, toRefs} from "vue";
  import FabButton from "@/components/FabButton.vue";
  import DateRangeMenu from "@/components/DateRangeMenu.vue";
  import DateRangeBottomSheet from "@/components/DateRangeBottomSheet.vue";
  import OrderChecklistDetails from "@/views/Orders/OrderChecklistDetailsDialog.vue";
  import OrdersList from "@/views/Orders/OrdersList.vue";
  import OrdersTable from "@/views/Orders/OrdersTableV2.vue";
  import SwitchView from "@/components/SwitchView.vue";
  import Order from "@/model/Order";
  import DateRangeUtils, {DateRange, DateRangeUpdateVO, DateRangeVO} from "@/components/DateRangeUtils";
  import OrderServiceV2 from "@/service/OrderServiceV2";
  import PageUtils from "@/components/PageUtils";
  import {OrderType, OrderTypes} from "@/enums";
  import FilterUtils from "@/FilterUtils";
  import moment from "moment";
  import {t} from "@/mixin/mixins";
  import {useRoute, useRouter} from "vue-router/composables";
  import {VRow} from "vuetify/lib";
  import OrderCalendar from "@/views/Orders/Calendar/OrderCalendar.vue";
  import CustomerService from "@/service/CustomerService";
  import Customer from "@/model/Customer";
  import OrdersPlanningTable from "@/views/Orders/OrdersPlanningTable.vue";

  export enum PlanningConfirmationStatus {
    CONFIRMED = "CONFIRMED",
    UNCONFIRMED = "UNCONFIRMED",
  }

  export default defineComponent({
    name: "OrdersV2",
    components: {
      DateRangeMenu,
      DateRangeBottomSheet,
      FabButton,
      OrderChecklistDetails,
      OrdersList,
      OrdersTable,
      OrdersPlanningTable,
      SwitchView,
      VRow,
      OrderCalendar
    },
    setup(props, context) {

      const route = useRoute();
      const router = useRouter();

      const initialState = {
        activeTab: 0,
        orderType: "",
        orderTypes: [],
        orders: new Array<Order>(),
        search: "",
        customer: null as Customer | null,
        defaultPlanningDateRange: DateRange.NEXT_TWO_WEEKS,
        planningConfirmationStatus: null as PlanningConfirmationStatus | null,
        planningDateRange: DateRangeUtils.getDates(DateRange.NEXT_TWO_WEEKS) as DateRangeVO,
        positions: 0,
        yearMonth: moment().format("YYYY-MM"),
      };

      const state = reactive({...initialState});

      const loadOrders = (validFrom: string, validTo: string) => {
        OrderServiceV2.findOrdersInTimeframe(validFrom, validTo, "checklist")
          .then(response => {
            state.orders = PageUtils.extractCollection<Order>(response.data, "orders");
          });
      };

      const createOrder = () => {
        router.push({path: "/orders/new"});
      };

      const openOrder = (order: Order) => {
        router.push({
          name: "OrderWorklogs",
          params: {orderId: order.id as unknown as string}
        });
      };

      const planningConfirmationStatusText = (item: PlanningConfirmationStatus) => {
        return t("enum.confirmationStatus." + item);
      };

      const planningConfirmationStatusAsType = computed(() => {
        switch (state.planningConfirmationStatus) {
        case "CONFIRMED":
          return PlanningConfirmationStatus.CONFIRMED;
        case "UNCONFIRMED":
          return PlanningConfirmationStatus.UNCONFIRMED;
        default:
          return null;
        }
      });

      const orderTypeName = (item: any) => {
        return t("enum.orderType." + item.code);
      };

      const updateDate = (dateRange: DateRangeUpdateVO) => {
        if (route.query.from !== dateRange.from
          && route.query.to !== dateRange.to) {

          if (dateRange.from && dateRange.to) {
            router.push({
              name: "OrdersV2",
              query: {from: dateRange.from, to: dateRange.to}
            });
            loadOrders(dateRange.from, dateRange.to);
          }
        }
      };

      const updatePlanningDates = (dateRange: DateRangeUpdateVO) => {
        if (dateRange.from && dateRange.to) {
          state.planningDateRange = new DateRangeVO(moment(dateRange.from), moment(dateRange.to));
        }
      };

      const filteredOrders = computed(() => {
        let filtered = state.orders.slice(0);
        if (state.orderType) {
          filtered = filtered.filter(order => {
            return FilterUtils.compare(order.orderType, state.orderType);
          });
        }

        if (!state.search || state.search === "") {
          return filtered;
        } else {
          return filtered.filter(
            order => {
              const orderTypeName = t("enum.orderType." + order.orderType);
              return FilterUtils.compare(order.name, state.search)
                || FilterUtils.compare(order.city, state.search)
                || FilterUtils.compare(order.address, state.search)
                || FilterUtils.compare(order.zip, state.search)
                || FilterUtils.compare(orderTypeName, state.search);
            });
        }
      });

      const inventoryOrders = computed(() => {
        let filtered = state.orders.slice(0);
        filtered = filtered.filter(order => {
          return FilterUtils.compare(order.orderType, OrderType.INV);
        });
        return filtered;
      });

      const planningConfirmationStatusList = [
        PlanningConfirmationStatus.CONFIRMED,
        PlanningConfirmationStatus.UNCONFIRMED
      ];
      const orderTypes = OrderTypes;

      onMounted(() => {
        let dateParamsPassedAndValid = false;

        let validFrom = "";
        let validTo = "";

        if (route.query.from && route.query.to) {
          const fromValid = moment(route.query.from as string, "YYYY-MM-DD", true).isValid();
          const toValid = moment(route.query.to as string, "YYYY-MM-DD", true).isValid();
          if (fromValid && toValid) {
            dateParamsPassedAndValid = true;
            validFrom = moment(route.query.from as string).format("YYYY-MM-DD");
            validTo = moment(route.query.to as string).format("YYYY-MM-DD");
          }
        }

        if (!dateParamsPassedAndValid) {
          validFrom = moment().subtract(14, "days").format("YYYY-MM-DD");
          validTo = moment().format("YYYY-MM-DD");
        }

        loadOrders(validFrom, validTo);

        if (route.query.customerId) {
          const id = parseInt(route.query.customerId as string);
          CustomerService.getCustomerById(id)
            .then((customer) => {
              state.customer = customer;
              if (route.query.positions) {
                state.positions = parseInt(route.query.positions as string);
              }
              state.activeTab = 1;
            });
        }
      });

      return {
        ...toRefs(state),
        createOrder,
        openOrder,
        filteredOrders,
        inventoryOrders,
        orderTypeName,
        orderTypes,
        planningConfirmationStatusAsType,
        planningConfirmationStatusList,
        planningConfirmationStatusText,
        updateDate,
        updatePlanningDates
      };
    }
  });
