
  import Vue from "vue";
  import moment, {Moment} from "moment";
  import DateRangeUtils, {DateRange, DateRangeUpdateVO} from "@/components/DateRangeUtils";

  export default Vue.extend({
    name: "DateRangeSelection",
    props: {
      defaultDateRange: {
        type: Number,
        default: DateRange.LAST_TWO_WEEKS
      },
      format: {
        type: String,
        default: "DD.MM.YYYY"
      }
    },
    data: () => ({
      panel: 0,
      selectedDateRange: (null as unknown as DateRange | null),
      selectedItem: 0,
      fromDialog: false,
      toDialog: false,
      from: "",
      to: ""
    }),
    computed: {
      dateText(): string {
        return DateRangeUtils.dateText(this.t, this.selectedDateRange as DateRange, this.from, this.to);
      }
    },
    mounted() {
      this.selectedDateRange = this.defaultDateRange;
      // Since the enum is number based this will equal the enum selection
      this.selectedItem = this.defaultDateRange;
      let dates = DateRangeUtils.getDates(this.defaultDateRange);
      this.from = dates.from.format("YYYY-MM-DD");
      this.to = dates.to.format("YYYY-MM-DD");
    },
    methods: {
      applyDates() {
        this.selectedDateRange = null;
        const from = moment(this.from, "YYYY-MM-DD", true);
        const to = moment(this.to, "YYYY-MM-DD", true);
        this.updateDates(from, to);
      },
      lastTwoWeeks() {
        this.selectedDateRange = DateRange.LAST_TWO_WEEKS;
        this.updateDateRange(DateRange.LAST_TWO_WEEKS);
      },
      nextTwoWeeks() {
        this.selectedDateRange = DateRange.NEXT_TWO_WEEKS;
        this.updateDateRange(DateRange.NEXT_TWO_WEEKS);
      },
      currentMonth() {
        this.selectedDateRange = DateRange.CURRENT_MONTH;
        this.updateDateRange(DateRange.CURRENT_MONTH);
      },
      lastMonth() {
        this.selectedDateRange = DateRange.LAST_MONTH;
        this.updateDateRange(DateRange.LAST_MONTH);
      },
      currentYear() {
        this.selectedDateRange = DateRange.CURRENT_YEAR;
        this.updateDateRange(DateRange.CURRENT_YEAR);
      },
      lastYear() {
        this.selectedDateRange = DateRange.LAST_YEAR;
        this.updateDateRange(DateRange.LAST_YEAR);
      },
      updateDateRange(dateRange: DateRange) {
        let dates = DateRangeUtils.getDates(dateRange);
        this.updateDates(dates.from, dates.to);
      },
      updateDates(from: Moment, to: Moment) {
        const fromString = from.format("YYYY-MM-DD");
        const toString = to.format("YYYY-MM-DD");
        const dateRangeSelection = new DateRangeUpdateVO(this.selectedDateRange, fromString, toString);
        this.$emit("date-change", dateRangeSelection);
        this.from = fromString;
        this.to = toString;
      }
    }
  });
