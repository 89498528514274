import {ValidationResult} from "@/model/ValidationResult";
import {RegistrationData} from "@/model/Registration";
import {computed, nextTick, onMounted, reactive, Ref, SetupContext, watch} from "vue";
import {RegistrationActions, RegistrationGetters} from "@/store/modules/registration";
import {RegistrationType} from "@/enums";
import {registrationUuid} from "@/RouterUtils";
import {MessageTypes} from "@/components/message-types";
import {AxiosError} from "axios";
import {Validation, ValidationArgs} from "@vuelidate/core";
import eventBus from "@/eventbus";
import store from "@/store/store";
import vuetify from "@/plugins/vuetify";

export function useDialog<Vargs extends ValidationArgs = ValidationArgs>(context: SetupContext, afterValidationCallback?: () => void): any {

  const initialState = {
    model: {} as RegistrationData,
    dirty: false,
    mounted: false,
    serverErrors: new Array<ValidationResult>()
  };

  const state = reactive({...initialState});

  watch(() => state.model, (newValue, oldValue) => {
    if (state.mounted) {
      state.dirty = true;
    }
  }, {deep: true});

  onMounted(() => {
    nextTick(() => {
      state.mounted = true;
    });
  });

  const registration = computed(() => {
    return store.getters[RegistrationGetters.GET_REGISTRATION];
  });

  const registrationType = computed(() => {
    return store.getters[RegistrationGetters.GET_REGISTRATION_TYPE];
  });

  const isWorkPermitShown = computed(() => {
    return store.getters[RegistrationGetters.GET_WORK_PERMIT_SHOWN];
  });

  const socialSecurity = computed(() => {
    return registrationType.value === RegistrationType.MODIFICATION_HELPER || registrationType.value === RegistrationType.SOCIAL_SECURITY || registrationType.value === RegistrationType.STOCK_REPLENISHMENT_MINOR_EMPLOYMENT;
  });

  const minorEmployment = computed(() => {
    return registrationType.value === RegistrationType.MINOR_EMPLOYMENT || registrationType.value === RegistrationType.WORKING_STUDENT || registrationType.value === RegistrationType.STOCK_REPLENISHMENT_MINOR_EMPLOYMENT;
  });

  const shortTerm = computed(() => {
    return registrationType.value === RegistrationType.SHORT_TERM;
  });

  const workingStudent = computed(() => {
    return registrationType.value === RegistrationType.WORKING_STUDENT;
  });

  const falseValue = computed(() => {
    return false;
  });

  const trueValue = computed(() => {
    return true;
  });

  const mobile = computed(() => {
    return vuetify.framework.breakpoint.smAndDown;
  });

  // FIXME
  // @ts-ignore
  const updateRegistration = (model: any, onSuccess: Function) => {
    state.serverErrors.length = 0;

    eventBus.$emit(MessageTypes.LOAD);
    store.dispatch(RegistrationActions.UPDATE_REGISTRATION, {
      registrationUuid: registrationUuid.value,
      registration: model
    })
        .then(() => {
          onSuccess();
        })
        .catch((error: AxiosError) => {
          if (error && error.response && error.response.status === 422) {
            // @ts-ignore
            error.response.data.errors.forEach((validationResult: ValidationResult) => {
              state.serverErrors.push(validationResult);
            });
          }
        })
        .finally(() => eventBus.$emit(MessageTypes.LOADING_FINISHED));
  };

  const updateIfNecessary = (v$: Ref<Validation<Vargs, any>>, model: any) => {
    if (state.dirty) {
      v$.value.$reset();
      v$.value.$touch();

      if (v$.value.$error) {
        return;
      }

      if (afterValidationCallback) {
        // @ts-ignore
        afterValidationCallback.call(this);
      }

      updateRegistration(model, () => {
        context.emit("next");
      });
    } else {
      context.emit("next");
    }
  };

  const next = (v$: Ref<Validation<Vargs, any>>, model: any) => {
    updateIfNecessary(v$, model);
  };

  const generalState = state;
  return {
    state,
    generalState,
    isWorkPermitShown,
    minorEmployment,
    registrationUuid,
    registration,
    registrationType,
    shortTerm,
    workingStudent,
    socialSecurity,
    falseValue,
    trueValue,
    mobile,
    next
  };
}
