
  import Vue from "vue";
  import DateRangeSelection from "@/components/DateRangeSelection.vue";
  import DateRangeUtils, {DateRange, DateRangeUpdateVO} from "@/components/DateRangeUtils";

  export default Vue.extend({
    name: "DateRangeMenu",
    components: {
      DateRangeSelection
    },
    props: {
      defaultDateRange: {
        type: Number,
        default: DateRange.LAST_TWO_WEEKS
      }
    },
    data: () => ({
      dialog: false,
      selectedDateRange: (null as unknown as DateRange | null),
      currentDateSelection: (null as unknown as DateRangeUpdateVO | null)
    }),
    computed: {
      dateText(): string {
        if (!this.currentDateSelection) {
          return DateRangeUtils.dateText(this.t, this.defaultDateRange, null, null);
        } else {
          const dateRange = this.currentDateSelection.dateRange;
          const from = this.currentDateSelection.from;
          const to = this.currentDateSelection.to;
          return DateRangeUtils.dateText(this.t, dateRange, from, to);
        }
      },
      currentMonthSelected(): boolean {
        return this.selectedDateRange === DateRange.CURRENT_MONTH;
      }
    },
    mounted() {
      this.selectedDateRange = this.defaultDateRange;
    },
    methods: {
      updateDates(updatedDates: DateRangeUpdateVO) {
        this.currentDateSelection = updatedDates;
        this.dialog = false;
        this.$emit("date-change", updatedDates);
      }
    }
  });
