
  import {defineComponent, PropType} from "vue";
  import {OrderTask} from "@/model/OrderTaskImport";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import {employeeId} from "@/store/StoreUtils";

  export default defineComponent({
    name: "OrdersListTile",
    props: {
      task: {
        type: Object as PropType<OrderTask>,
        required: true,
        default: () => {
        },
      },
      isManager: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context) {

      const onCheckbox = (task: OrderTask) => {
        if (task) eventBus.$emit(MessageTypes.CHECK_TASK, task);
      };

      const openTaskDialog = (task: OrderTask) => {
        context.emit("openTaskDialog", task);
      };

      const canCheckTask = (task: OrderTask) => {
        const myId = parseInt(employeeId.value);
        return props.isManager ? true : task.employeeId == myId;
      };

      return {
        onCheckbox,
        openTaskDialog,
        canCheckTask
      };
    }
  });
