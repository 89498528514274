<template>
  <VAutocomplete
    ref="autocomplete"
    v-model="select"
    label="Mitarbeiter hinzufügen"
    no-data-text="Keine Daten verfügbar"
    :loading="loading"
    :items="searchItems"
    item-text="fullName"
    item-value="id"
    return-object
    :search-input.sync="search"
    @input="selected => employeeSelected(selected)"
    @keydown.enter="onEnter"
    @update:list-index="updateListIndex"
  />
</template>

<script>

  import OrderService from "@/service/OrderService";

  export default {
    props: {
      orderId: {
        type: Number,
        default: null
      },
      toHide: {
        type: Array,
        default: () => []
      },
      assignmentType: {
        type: String,
        default: null
      }
    },
    data: () => ({
      listIndex: -1,
      loading: false,
      search: null,
      select: [],
      searchItems: [],
      typeTimeout: null,
      timeoutDuration: 200
    }),

    watch: {
      search(term) {
        clearTimeout(this.typeTimeout);

        const vue = this;
        if (term != null && term.length > 2) {
          this.typeTimeout = setTimeout(function () {
            term && vue.findEmployees(term);
          }, vue.timeoutDuration);
        }
      }
    },
    methods: {
      findEmployees(term) {
        const vue = this;
        this.loading = true;

        OrderService.findOrderEmployees(this.orderId, term, this.assignmentType)
          .then(response => {
            const vue = this;

            let itemsToShow = response.data;
            itemsToShow = itemsToShow.filter(function (item) {
              let keep = true;
              vue.toHide.forEach(function (itemToHide) {
                if (itemToHide.id === item.id) {
                  keep = false;
                }
              });

              return keep;
            });
            this.enhanceItemsForSelection(itemsToShow);
            this.searchItems = itemsToShow;
            this.loading = false;
          })
          .catch(function (error) {
            vue.$eventBus.$emit("error", error);
          });
      },
      enhanceItemsForSelection: function (items) {
        items.forEach(function (item) {
          item.fullName = item.firstName + " " + item.lastName + " (" + item.employeeId + ")";
        });
      },
      updateListIndex: function (index) {
        this.listIndex = index;
      },
      onEnter: function () {
        this.search = null;
        this.$nextTick(() => {
          this.select = null;
        });
        this.$emit("selected", this.searchItems[this.listIndex]);
        this.searchItems = [];
        this.listIndex = -1;
      },
      employeeSelected: function (item) {
        this.search = null;
        this.$nextTick(() => {
          this.select = null;
          this.$refs.autocomplete.blur();
        });
        this.searchItems = [];
        this.$emit("selected", item);
      }
    }
  };
</script>
<style>
.theme--light.v-list__tile--highlighted {
    background-color: #bfbfbfc4 !important;
}
</style>
