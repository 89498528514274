
  import {OrderTask} from "@/model/OrderTaskImport";
  import {defineComponent, PropType} from "vue";
  import InventoriesTasksListTile from "./InventoriesTasksListTile.vue";

  export default defineComponent({
    name: "InventoriesTasksList",
    components: {
      InventoriesTasksListTile
    },
    props: {
      tasks: {
        type: Array as PropType<OrderTask[]>,
        default: () => new Array<OrderTask>()
      },
      isManager: {
        type: Boolean,
        default: false
      },
      isCompleted: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context) {
      const openTaskDialog = (task: OrderTask) => {
        context.emit("openTaskDialog", task);
      };

      return {
        openTaskDialog
      };
    }
  });

