
  import {computed, defineComponent, PropType, reactive, Ref, toRefs, watch} from "vue";
  import EmployeeService from "@/service/EmployeeService";
  import PageUtils from "@/components/PageUtils";
  import Employee from "@/model/Employee";
  import {MessageTypes} from "@/components/message-types";
  import {t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";

  export default defineComponent({
    name: "EmployeeAutocomplete",
    props: {
      value: {
        type: Object as PropType<Employee>,
        default: () => null,
      },
      errorMessages: {
        type: [String, Object] as PropType<string | Ref<string>>,
        default: () => null
      }
    },
    setup(props, context) {
      const initialState = {
        loading: false,
        items: new Array<Employee>(),
        search: null as unknown as string | null,
      };

      const state = reactive({...initialState});

      const itemText = (item: Employee) => {
        if(item) {
          return item.firstName + " " + item.lastName;
        } else {
          return null;
        }
      };

      const prepopulate = (item: Employee | null) => {
        if(item) {
          state.items.push(item);
          // Prepopulate search to not trigger immediate call
          state.search = itemText(item);
        }
      };

      watch(() => props.value, (newValue, oldValue) => {
        if(newValue !== oldValue) {
          prepopulate(newValue);
        }
      });

      if(props.value) {
        prepopulate(props.value);
      }

      const model = computed({
        get: () => props.value,
        set: (value) => context.emit("input", value)
      });

      watch(() => state.search, (newValue, oldValue) => {
        let timeout = 200;
        clearTimeout(timeout);

        // Do not search in case the new value was set due to a prop change
        if (newValue != null && newValue.length > 2 && newValue !== itemText(props.value)) {
          state.loading = true;

          timeout = setTimeout(function () {
            EmployeeService.findByTerm(newValue)
              .then(response => {
                state.items = PageUtils.extractCollection<Employee>(response.data, "employees");
              })
              .catch((error) => {
                eventBus.$emit(MessageTypes.ERROR, t("errors.generalError"));
              })
              .finally(() => state.loading = false);
          }, timeout);
        }
      });

      return {...toRefs(state), model, itemText};
    }
  });
