
  import { computed, defineComponent, onMounted, reactive, Ref, toRefs } from "vue";
  import InventoriesTasksForm from "../forms/InventoriesTasksForm.vue";
  import ModalDialog from "@/components/ModalDialog.vue";
  import { OrderTask } from "@/model/OrderTaskImport";
  import {helpers, required} from "@vuelidate/validators";
  import { t } from "@/mixin/mixins";
  import useVuelidate from "@vuelidate/core";
  import eventBus from "@/eventbus";
  import { MessageTypes } from "@/components/message-types";
  import OrderTaskService from "@/service/OrderTaskService";
  import { AxiosError } from "axios";

  export default defineComponent({
    name: "InventoriesTasksDialog",
    components: {
      InventoriesTasksForm,
      ModalDialog
    },
    setup() {
      const state = reactive({
        dialog: false,
        edit: false,
        task: {} as OrderTask,
        orders: [],
        oldTaskLabel: "",
        serverErrors: new Map<string, string>()
      });

      onMounted(() => {
        eventBus.$on(MessageTypes.OPEN_TASK_DIALOG, (editedTask: OrderTask, orders: []) => {
          state.serverErrors = new Map<string, string>();

          if (editedTask) {
            state.edit = true;
            state.task = {...editedTask};
            if (state.task.label) state.oldTaskLabel = state.task.label;
          } else {
            state.edit = false;
            state.task = {} as OrderTask;
            state.task.id = -1;
            state.task.priority = 0;
            state.oldTaskLabel = "";
          }

          if (orders) {
            state.orders = orders;
          }

          state.dialog = true;
        });
      });

      const onTaskChanged = (newTask: OrderTask) => {
        state.task = newTask;
      };

      const save = () => {
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
        v$.value.$touch();

        if (!v$.value.$invalid) {
          if (!state.task.label || !state.task.label.trim()) state.task.label = null;

          if (state.task.id == -1) {
            OrderTaskService.createOrderTask(state.task)
              .then(response => {
                const newTask = response.data;
                eventBus.$emit(MessageTypes.TASK_CREATED, newTask);
                closeDialog();
                eventBus.$emit(MessageTypes.SUCCESS, t("inventories.tasks.message.success.create"), 1000);
              })
              .catch((error: AxiosError) => {
                if (error && error.response && error.response.status === 422) {
                  eventBus.$emit(MessageTypes.WARNING, t("checkInput"));
                  const errorMap = new Map<string, string>();
                  // @ts-ignore
                  error.response.data.forEach((error: ValidationError) => {
                    errorMap.set(error.path, error.message);
                  });
                  state.serverErrors = errorMap;
                } else {
                  eventBus.$emit(MessageTypes.ERROR, t("inventories.tasks.message.error.create"));
                }
              });
          } else {
            OrderTaskService.updateOrderTask(state.task)
              .then(response => {
                const newTask = response.data;
                eventBus.$emit(MessageTypes.TASK_CREATED, newTask);
                closeDialog();
              })
              .catch((error: AxiosError) => {
                if (error && error.response && error.response.status === 422) {
                  eventBus.$emit(MessageTypes.WARNING, t("checkInput"));
                  const errorMap = new Map<string, string>();
                  // @ts-ignore
                  error.response.data.forEach((error: ValidationError) => {
                    errorMap.set(error.path, error.message);
                  });
                  state.serverErrors = errorMap;
                } else {
                  eventBus.$emit(MessageTypes.ERROR, t("inventories.tasks.message.error.edit"));
                }
              });
          }
        }
      };

      const closeDialog = () => {
        state.dialog = false;
        state.task = {} as OrderTask;
        state.orders = [];
        state.oldTaskLabel = "";
        state.serverErrors = new Map<string, string>();
        v$.value.$reset();
      };

      const rules = computed(() => ({
        task: {
          orderId: {
            required: helpers.withMessage(t("inventories.tasks.validation.orderId"), required),
          },
          label: {
            required: helpers.withMessage(t("inventories.tasks.validation.label"), required),
          },
          positions: {
            required: helpers.withMessage(t("inventories.tasks.validation.positions"), required),
          },
          employeeId: {
            required: helpers.withMessage(t("inventories.tasks.validation.employeeId"), required),
          }
        }
      }));

      // @ts-ignore
      const v$ = useVuelidate(rules, state);
      
      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        if (state.serverErrors.size > 0) {
          return state.serverErrors;
        } else {
          const model = v$.value.task;
          if (model.$dirty) {
            if(model.orderId.$error) {
              errors.set("orderId", model.orderId.$errors[0].$message);
            }
            if(model.label.$error) {
              errors.set("label", model.label.$errors[0].$message);
            }
            if(model.positions.$error) {
              errors.set("positions", model.positions.$errors[0].$message);
            }
            if(model.employeeId.$error) {
              errors.set("employeeId", model.employeeId.$errors[0].$message);
            }
          }
        }
        return errors;
      });

      return {
        ...toRefs(state),
        onTaskChanged,
        save,
        closeDialog,
        errors
      };
    }
  });
