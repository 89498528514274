import axios, {AxiosResponse} from "axios";
import {OrderTask, OrderTaskImport} from "@/model/OrderTaskImport";
import {PagedResult} from "@/service/PagedResult";

const PATH = "/order-task";
const PATH_IMPORT = `${PATH}/import`;

class OrderTaskService {

    // region ORDER TASK IMPORT
    findOrderTaskImports(): Promise<AxiosResponse<PagedResult>> {
        return axios.get(PATH_IMPORT, {
            params: {
                size: 1000,
                sort: "name,asc"
            }
        });
    }

    getOrderTaskImportByOrderId(orderId: number): Promise<AxiosResponse<OrderTaskImport>> {
        return axios.get(`${PATH_IMPORT}/order/${orderId}`);
    }

    createOrderTaskImport(payload: OrderTaskImport): Promise<AxiosResponse<OrderTaskImport>> {
        return axios.post(PATH_IMPORT, payload, {headers: {"Content-Type": "application/json"}});
    }

    deleteOrderTaskImport(id: number): Promise<AxiosResponse<null>> {
        return axios.delete(`${PATH_IMPORT}/${id}`);
    }

    // endregion

    // region ORDER TASK
    findOrderTasksByEmployeeId(employeeId: number, date: string): Promise<AxiosResponse<Array<OrderTask>>> {
        return axios.get(`${PATH}/employee/${employeeId}`, {
            params: {
                date: date
            }
        });
    }

    findAllOrderTasks(date: string): Promise<AxiosResponse<Array<OrderTask>>> {
        return axios.get(PATH, {
            params: {
                date: date
            }
        });
    }

    createOrderTask(payload: OrderTask): Promise<AxiosResponse<OrderTask>> {
        return axios.post(PATH, payload, {headers: {"Content-Type": "application/json"}});
    }

    updateOrderTask(payload: OrderTask): Promise<AxiosResponse<OrderTask>> {
        return axios.put(`${PATH}/${payload.id}`, payload, {headers: {"Content-Type": "application/json"}});
    }

    // endregion
}

export default new OrderTaskService();
