import {onMounted, ref, SetupContext} from "vue";
import PageUtils from "@/components/PageUtils";
import {InventoryConfirmation} from "@/model/InventoryConfirmation";
import InventoryConfirmationService from "@/service/InventoryConfirmationService";
import moment from "moment/moment";
import {Moment} from "moment";
import {PlanningConfirmationStatus} from "@/views/Orders/OrdersV2.vue";

export const initDialog = (context: SetupContext) => {
  const confirmations = ref<Array<InventoryConfirmation>>(new Array<InventoryConfirmation>());

  const fetchConfirmations = (from: Moment, to: Moment, confirmationStatus?: PlanningConfirmationStatus) => {
    let confirmed = undefined;
    if (confirmationStatus) {
      confirmed = confirmationStatus == PlanningConfirmationStatus.CONFIRMED;
    }

    InventoryConfirmationService.getConfirmations(from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD"), confirmed)
        .then(response => {
          confirmations.value = PageUtils.extractCollection(response.data, "confirmations");
        });
  };

  onMounted(() => {
    const from = moment();
    const to = moment().add(14, "days");
    fetchConfirmations(from, to);
  });

  return {
    fetchConfirmations,
    confirmations
  };
};
