import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VListItem,{class:_vm.task.completed ? 'item-completed' : 'item-pending'},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"white-space-normal"},[_vm._v(" "+_vm._s(_vm.task.label)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.t("inventories.tasks.table.order"))+": "+_vm._s(_vm.task.orderName)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.t("inventories.tasks.table.employee"))+": "+_vm._s(_vm.task.employeeName)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.t("inventories.tasks.table.positions"))+": "+_vm._s(_vm.task.positions)+" ")]),(_vm.task.completed)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.t("inventories.tasks.table.completedDate"))+": "+_vm._s(_vm._f("formatDate")(_vm.task.completedDate,'DD.MM.YY, HH:mm'))+" ")]):_vm._e()],1),_c(VListItemIcon,[_c('div',{staticClass:"d-flex align-center justify-center"},[(_vm.canCheckTask(_vm.task))?_c(VCheckbox,{attrs:{"input-value":_vm.task.completed},nativeOn:{"!click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onCheckbox(_vm.task)}}}):_vm._e(),(_vm.isManager)?_c(VBtn,{staticClass:"mb-1",attrs:{"icon":""},on:{"click":function($event){return _vm.openTaskDialog(_vm.task)}}},[_c(VIcon,[_vm._v(" mdi-pencil ")])],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }