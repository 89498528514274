
  import {OrderTask} from "@/model/OrderTaskImport";
  import {defineComponent, PropType} from "vue";
  import {t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import EmployeeService from "@/service/EmployeeService";
  import {employeeId} from "@/store/StoreUtils";

  export default defineComponent({
    name: "InventoriesTasksTable",
    components: {},
    props: {
      tasks: {
        type: Array as PropType<OrderTask[]>,
        default: () => new Array<OrderTask>()
      },
      isManager: {
        type: Boolean,
        default: false
      },
      isCompleted: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context) {
      const headers = [
        {
          text: t("inventories.tasks.table.order"),
          align: "left",
          value: "order",
          sortable: false
        },
        {
          text: t("inventories.tasks.table.label"),
          align: "left",
          value: "label",
          sortable: false
        },
        {
          text: t("inventories.tasks.table.positions"),
          align: "left",
          value: "positions",
          sortable: false
        },
        {
          text: t("inventories.tasks.table.employee"),
          align: "left",
          value: "employee",
          sortable: false
        },
        {
          text: props.isCompleted ? t("inventories.tasks.table.completedDate") : "",
          align: "left",
          value: "completedDate",
          sortable: false,
          width: props.isCompleted ? "" : 0
        },
        {
          value: "checkbox",
          align: "right",
          sortable: false,
          width: 0
        },
        {
          value: "editEmployee",
          align: "right",
          sortable: false,
          width: 0
        }
      ];

      const onCheckbox = (task: OrderTask) => {
        if (task) eventBus.$emit(MessageTypes.CHECK_TASK, task);
      };

      const openTaskDialog = (task: OrderTask) => {
        context.emit("openTaskDialog", task);
      };

      const canCheckTask = (task: OrderTask) => {
        const myId = parseInt(employeeId.value);
        return props.isManager ? true : task.employeeId == myId;
      };

      return {
        headers,
        onCheckbox,
        openTaskDialog,
        canCheckTask
      };
    },
  });

