
  import {defineComponent, PropType, ref, SetupContext, watch} from "vue";
  import {initDialog} from "@/views/Orders/OrdersPlanningSetup";
  import router from "@/router";
  import {InventoryConfirmation} from "@/model/InventoryConfirmation";
  import moment from "moment";
  import {t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import OrdersPlanningInventoryConfirmationDialog from "@/views/Orders/OrdersPlanningInventoryConfirmationDialog.vue";
  import {DateRangeVO} from "@/components/DateRangeUtils";
  import {PlanningConfirmationStatus} from "@/views/Orders/OrdersV2.vue";

  export default defineComponent({
    name: "OrdersPlanningTable",
    components: {OrdersPlanningInventoryConfirmationDialog},
    props: {
      confirmationStatus: {
        type: String,
        default: null,
        validator(value: string) {
          return value == null || value == PlanningConfirmationStatus.CONFIRMED || value == PlanningConfirmationStatus.UNCONFIRMED;
        }
      },
      dateRange: {
        type: Object as PropType<DateRangeVO>,
        default: () => new DateRangeVO(moment(), moment()),
        required: true
      }
    },
    setup(props: any, context: SetupContext) {
      const footerProps = {
        "items-per-page-options": [-1]
      };

      const headers = [
        {
          text: t("date.text"),
          align: "left",
          value: "date"
        },
        {
          text: t("startTime"),
          align: "left",
          value: "startTime"
        },
        {
          text: t("name"),
          align: "left",
          value: "name"
        },
        {
          text: t("address.address"),
          align: "left",
          value: "address"
        },
        {
          text: t("address.zip"),
          align: "left",
          value: "zip"
        },
        {
          text: t("address.city"),
          align: "left",
          value: "city"
        },
        {
          text: t("order.inventoryConfirmations.confirmed"),
          align: "left",
          value: "confirmationTimestamp"
        },
        {
          text: t("order.inventoryConfirmations.flowers"),
          align: "left",
          value: "flowers"
        },
        {
          text: t("order.inventoryConfirmations.fruitAndVegetables"),
          align: "left",
          value: "fruitAndVegetables"
        },
        {
          text: t("order.inventoryConfirmations.labels"),
          align: "left",
          value: "labels"
        },
        {
          text: t("order.inventoryConfirmations.storage"),
          align: "left",
          value: "storage"
        },
        {
          text: t("order.inventoryConfirmations.targetActualComparison"),
          align: "left",
          value: "targetActualComparison"
        },
        {
          text: t("order.inventoryConfirmations.changedByCustomer"),
          align: "left",
          value: "changedByCustomer"
        },
        {
          text: t("order.inventoryConfirmations.storageRollCageCount"),
          align: "left",
          value: "storageRollCageCount"
        },
        {
          text: t("order.inventoryConfirmations.storageFreezerRollCageCount"),
          align: "left",
          value: "storageFreezerRollCageCount"
        },
        {
          text: t("comment"),
          align: "left",
          value: "comment"
        },
        {
          text: "",
          align: "right",
          value: "orderId",
          sortable: false,
          width: 80
        }
      ];

      const {confirmations, fetchConfirmations} = initDialog(context);

      const ordersPlanningInventoryConfirmationDialog = ref<InstanceType<typeof OrdersPlanningInventoryConfirmationDialog>>();
      const openInventoryConfirmation = (confirmation: InventoryConfirmation) => {
        ordersPlanningInventoryConfirmationDialog.value?.open(confirmation)
          .then(result => {
            if (result) {
              fetchConfirmations(props.dateRange.from, props.dateRange.to, props.confirmationStatus);
            }
          });
      };

      const openOrder = (confirmation: InventoryConfirmation) => {
        const routeData = router.resolve({
          name: "OrderInfo",
          params: {orderId: confirmation.orderId as unknown as string}
        });
        // Open in new tab
        window.open(routeData.href, "_blank");
      };

      const openComment = (confirmation: InventoryConfirmation) => {
        eventBus.$emit(MessageTypes.INFO, confirmation.name, confirmation.comment);
      };

      watch(() => props.dateRange, (newValue, oldValue) => {
        if (newValue && oldValue && newValue != oldValue) {
          fetchConfirmations(props.dateRange.from, props.dateRange.to, props.confirmationStatus);
        }
      });

      watch(() => props.confirmationStatus, (newValue, oldValue) => {
        if (newValue != oldValue) {
          fetchConfirmations(props.dateRange.from, props.dateRange.to, newValue);
        }
      });

      return {
        confirmations,
        footerProps,
        headers,
        openComment,
        openInventoryConfirmation,
        openOrder,
        ordersPlanningInventoryConfirmationDialog
      };
    }
  });
